import {APIRequest, APIResponse, AppApi, Pagination} from 'store/customer/api';
import {
    Door,
    Material,
    MaterialEdge,
} from 'components/customer/Materials/entity';
import {
    transformDisplayName,
    transformCustomColour,
    transformMaterialImage,
    transformDoorImage,
    MaterialEdgeSearchRequest,
    flattenByDoorFilter,
    transformHighlightSearch,
    transformDoorFilters,
    searchEdges,
    transformDisplayNameEdge,
    transformEdgeFinishValues,
    flattenChanges,
    filterDuplicates,
} from 'components/customer/Materials/helper';
import {Type} from 'components/customer/AdvancedMaterials/entity/Type';
import {Finish} from 'components/customer/AdvancedMaterials/entity/Finish';
import {Brand} from 'components/customer/AdvancedMaterials/entity/Brand';

export interface MaterialSearchRequest extends APIRequest {
    keywords?: string;
    manufacturerId?: number;
    cabinetType?: number;
    doorFilter?: string;
    materialType?: string;
    materialEdgeName?: string;
    doorName?: string;
    brands?: string[];
    finishes?: string[];
    sort?: string;
    brand?: string;
    finish?: string;
    id?: number;
}

export const MaterialApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        // NOTE: OLDAPI - replace endpoint when new API is ready
        getTypes: builder.query<
            Type[],
            {cabinetType?: number; defaultSelected?: boolean}
        >({
            query: ({cabinetType}) => {
                return {
                    url: 'product/material/types',
                    params: {
                        sort_orders: 'name ASC',
                        cabinet_type: cabinetType,
                    },
                };
            },
            transformResponse: (
                response: {
                    items: {id: string; name: string; is_vinyl: boolean}[];
                },
                {},
                {defaultSelected = true}
            ) => {
                return response.items.map((item) => ({
                    ...item,
                    selected: defaultSelected,
                    isVinyl: item.is_vinyl,
                }));
            },
            providesTags: ['MaterialTypes'],
        }),
        getMaterial: builder.query<
            APIResponse<Material>,
            MaterialSearchRequest
        >({
            query: ({keywords: id, cabinetType, manufacturerId}) => {
                const filterGroups: string[] = [];
                let filterGroupsString = '';

                if (typeof cabinetType !== 'undefined') {
                    filterGroups.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (typeof manufacturerId !== 'undefined') {
                    filterGroups.push(`((changes.is_hidden:equals:0))`);
                    filterGroups.push(
                        `((changes.manufacturer_id:equals:${manufacturerId}))`
                    );
                }

                if (filterGroups.length > 0) {
                    filterGroupsString = `&filter_groups=${filterGroups.join(
                        ' AND '
                    )}`;
                }

                return `product/materials/${id}?related_includes=brand,type,prefix${filterGroupsString}`;
            },
            transformResponse: (response: {
                data: Material;
                pagination: Pagination;
            }) => ({
                data: [response.data]
                    .map(transformDisplayName)
                    .map(transformCustomColour)
                    .map(transformMaterialImage)[0],
                pagination: response.pagination,
            }),
            providesTags: (result, error, {keywords: id}) => [
                {type: 'Material', id},
            ],
        }),
        listMaterials: builder.query<
            APIResponse<Material[]>,
            MaterialSearchRequest
        >({
            query: ({
                cabinetType,
                currentPage = 1,
                pageSize = 1,
                doorFilter,
                brands = [],
                finishes = [],
                sort = 'id DESC',
                keywords,
                manufacturerId,
                materialType,
            }) => {
                const filterGroups: string[] = [];
                let filterGroupsString = '';

                if (typeof cabinetType !== 'undefined') {
                    filterGroups.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (typeof manufacturerId !== 'undefined') {
                    filterGroups.push(`((changes.is_hidden:equals:0))`);
                    filterGroups.push(
                        `((changes.manufacturer_id:equals:${manufacturerId}))`
                    );
                }

                filterGroups.push(`((changes.is_hidden:equals:0))`);

                if (keywords && keywords.length > 0) {
                    filterGroups.push(`((name:contains:${keywords}))`);
                }

                if (doorFilter) {
                    filterGroups.push(`((door_filter:equals:${doorFilter}))`);
                }

                if (brands.length > 0) {
                    filterGroups.push(
                        `((brand_id:either:${brands.join(',')}))`
                    );
                }

                if (materialType) {
                    filterGroups.push(`((type_id:equals:${materialType}))`);
                }

                if (finishes.length > 0) {
                    filterGroups.push(
                        `((finish:either:${finishes.join(',')}))`
                    );
                }

                if (filterGroups.length > 0) {
                    filterGroupsString = `&filter_groups=${filterGroups.join(
                        ' AND '
                    )}`;
                }

                return `product/materials?related_includes=brand,type,prefix&current_page=${currentPage}&page_size=${pageSize}&sort_orders=${sort}${filterGroupsString}`;
            },
            transformResponse: (
                response: {
                    items: Material[];
                    pagination: Pagination;
                    group_count?: number;
                },
                _,
                {doorFilter}
            ) => {
                let data = response.items
                    .map(transformDisplayName)
                    .map(transformCustomColour)
                    .map(transformMaterialImage);

                if (typeof doorFilter === 'undefined' || doorFilter == '') {
                    data = filterDuplicates(data);
                }

                return {
                    data,
                    pagination: response.pagination,
                    groupCount: response.group_count,
                };
            },
            providesTags: (result, error, params) => [
                {type: 'Material', ...params},
            ],
        }),
        getDoor: builder.query<APIResponse<Door>, MaterialSearchRequest>({
            query: ({keywords: id, manufacturerId, cabinetType}) => {
                let filterGroups = '';
                let queryParam = '';

                if (typeof cabinetType !== 'undefined') {
                    filterGroups = `&filter_groups=((cabinet.type:equals:${cabinetType}))`;
                    queryParam = `&cabinet_type=${cabinetType}`;
                }

                if (typeof manufacturerId !== 'undefined') {
                    return `product/doors/${id}?related_includes=image,suffix,changes${queryParam}&changes_filter_groups=((manufacturer_id:equals:${manufacturerId}))&changes_page_size=1${filterGroups}`;
                }

                return `product/doors/${id}?related_includes=image,suffix${queryParam}${filterGroups}`;
            },
            transformResponse: (response: {
                data: Door;
                pagination: Pagination;
            }) => {
                const doors: Door[] = [response.data];

                let door: Door;
                if (doors.length) {
                    door = doors
                        .map(transformDoorImage)
                        .map(transformEdgeFinishValues)
                        .map(flattenChanges)[0];
                }

                return {
                    data: door,
                    pagination: response.pagination,
                };
            },
            providesTags: (
                result,
                error,
                {keywords: id, cabinetType, manufacturerId}
            ) => [{type: 'Door', id, cabinetType, manufacturerId}],
        }),
        listDoors: builder.query<APIResponse<Door[]>, MaterialSearchRequest>({
            query: ({
                keywords,
                manufacturerId,
                cabinetType,
                currentPage = 1,
                pageSize = 1000,
                materialType,
            }) => {
                const filterGroups = [`((changes.is_hidden:equals:0))`];
                let changesFilterGroups = '';
                let queryParam = '';

                if (typeof materialType != 'undefined') {
                    filterGroups.push(
                        `((material.type:equals:${materialType}))`
                    );
                }

                if (typeof manufacturerId !== 'undefined') {
                    filterGroups.push(
                        `((changes.manufacturer_id:equals:${manufacturerId}))`
                    );
                    changesFilterGroups = `&changes_filter_groups=((manufacturer_id:equals:${manufacturerId}))`;
                }

                if (typeof cabinetType !== 'undefined') {
                    queryParam = `&cabinet_type=${cabinetType}`;
                }

                if (keywords) {
                    filterGroups.push(`((filter_name:equals:${keywords}))`);
                }

                let filterGroupsQuery = '';
                if (filterGroups.length > 0) {
                    filterGroupsQuery = `&filter_groups=${filterGroups.join(
                        ' AND '
                    )}`;
                }

                return `product/doors?current_page=${currentPage}${queryParam}&page_size=${pageSize}&sort_orders=name ASC&related_includes=image,suffix,changes${filterGroupsQuery}${changesFilterGroups}`;
            },
            transformResponse: (response: {
                items: Door[];
                pagination: Pagination;
            }) => ({
                data: response.items
                    .map(transformDoorImage)
                    .map(transformEdgeFinishValues)
                    .map(flattenChanges),
                pagination: response.pagination,
            }),
            providesTags: (
                result,
                error,
                {keywords: id, materialType, manufacturerId}
            ) => [
                {
                    type: 'Door',
                    id: typeof id !== 'undefined' ? id : materialType,
                    manufacturerId,
                },
            ],
        }),
        getMaterialEdge: builder.query<
            APIResponse<MaterialEdge>,
            MaterialSearchRequest
        >({
            query: ({keywords: id, cabinetType, manufacturerId}) => {
                const filterGroups: string[] = [];
                let filterGroupsString = '';

                if (cabinetType) {
                    filterGroups.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (manufacturerId) {
                    filterGroups.push(`((changes.is_hidden:equals:0))`);
                    filterGroups.push(
                        `((changes.manufacturer_id:equals:${manufacturerId}))`
                    );
                }

                if (filterGroups.length > 0) {
                    filterGroupsString = `&filter_groups=${filterGroups.join(
                        ' AND '
                    )}`;
                }

                return `product/edges/${id}?related_includes=brand,material_type${filterGroupsString}`;
            },
            transformResponse: (response: {
                data: MaterialEdge;
                pagination: Pagination;
            }) => ({
                data: [response.data]
                    .map(transformDisplayNameEdge)
                    .map(transformMaterialImage)[0],
                pagination: response.pagination,
            }),
            providesTags: (result, error, {keywords: id}) => [
                {type: 'MaterialEdge', id},
            ],
        }),
        listMaterialEdges: builder.query<
            APIResponse<MaterialEdge[]>,
            MaterialSearchRequest
        >({
            query: ({
                cabinetType,
                manufacturerId,
                doorFilter,
                brands,
                finishes,
                keywords,
                currentPage,
                pageSize,
                id,
                materialType,
                materialEdgeName,
            }) => {
                const filterGroups: string[] = [];
                let filterGroupsString = '';

                if (
                    typeof id !== 'undefined' &&
                    typeof materialEdgeName === 'undefined'
                ) {
                    filterGroups.push(`((id:equals:${id}))`);
                }

                if (typeof materialEdgeName !== 'undefined') {
                    filterGroups.push(`((name:equals:${materialEdgeName}))`);
                }

                if (typeof cabinetType !== 'undefined') {
                    filterGroups.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (keywords) {
                    filterGroups.push(`((name:contains:${keywords}))`);
                }

                if (typeof manufacturerId !== 'undefined') {
                    filterGroups.push(`((changes.is_hidden:equals:0))`);
                    filterGroups.push(
                        `((changes.manufacturer_id:equals:${manufacturerId}))`
                    );
                }

                if (doorFilter) {
                    filterGroups.push(`((door_filter:equals:${doorFilter}))`);
                }

                if (brands && brands.length > 0) {
                    filterGroups.push(
                        `((brand_id:either:${brands.join(',')}))`
                    );
                }

                if (finishes && finishes.length > 0) {
                    filterGroups.push(
                        `((finish:either:${finishes.join(',')}))`
                    );
                }

                if (materialType) {
                    filterGroups.push(
                        `((material_type_id:equals:${materialType}))`
                    );
                }

                if (filterGroups.length > 0) {
                    filterGroupsString = `&filter_groups=${filterGroups.join(
                        ' AND '
                    )}`;
                }

                return `product/edges/?current_page=${currentPage}&page_size=${pageSize}&related_includes=brand,material_type${filterGroupsString}&sort_orders=name ASC`;
            },
            transformResponse: (response: {
                items: MaterialEdge[];
                pagination: Pagination;
                group_count?: number;
            }) => ({
                data: response.items
                    .map(transformDisplayNameEdge)
                    .map(transformMaterialImage),
                pagination: response.pagination,
                groupCount: response.group_count,
            }),
            providesTags: (result, error, props) => [
                {type: 'MaterialEdge', ...props},
            ],
        }),
        searchMaterials: builder.query<
            APIResponse<Material[]>,
            MaterialSearchRequest
        >({
            query: ({
                keywords,
                cabinetType,
                currentPage = 1,
                pageSize = 20,
            }) => {
                let queryString = `keywords=${keywords}&current_page=${currentPage}&page_size=${pageSize}`;

                if (cabinetType) {
                    queryString += `&cabinet_type=${cabinetType}`;
                }

                return `product/materials/search?${queryString}&sort_orders=name,type.name,brand.name,finish,substrate,door_filter`;
            },
            transformResponse: (
                response: {
                    items: Material[];
                    pagination: Pagination;
                    group_count?: number;
                },
                meta,
                args
            ) => ({
                data: flattenByDoorFilter(
                    response.items
                        .map(transformDisplayName)
                        .map(transformMaterialImage)
                        .map(transformCustomColour)
                        .map(
                            transformHighlightSearch(
                                args.keywords,
                                'displayName',
                                'highlightedDisplayName'
                            )
                        )
                        .map(transformDoorFilters)
                ),
                pagination: response.pagination,
                groupCount: response?.group_count,
            }),
            providesTags: (result, error, params) => [
                {type: 'Material', ...params},
            ],
        }),
        searchDoors: builder.query<APIResponse<Door[]>, MaterialSearchRequest>({
            query: ({
                keywords,
                currentPage = 1,
                pageSize = 20,
                cabinetType,
                doorName,
                manufacturerId,
                materialType,
            }) => {
                const relatedIncludes = ['image', 'edge'];
                const filterGroups: string[] = [];
                let filterGroupsString = '';

                let queryString = `current_page=${currentPage}&page_size=${pageSize}`;
                let sort = `suffix_name,name ASC`;

                if (keywords) {
                    queryString += `&filter_names=${keywords}`;
                }

                if (cabinetType) {
                    queryString += `&cabinet_type=${cabinetType}`;
                }

                if (doorName && doorName != '') {
                    queryString += `&door_name=${doorName}`;
                }

                if (materialType) {
                    queryString += `&material_type=${materialType}`;
                    sort = 'name ASC';
                }

                if (manufacturerId) {
                    relatedIncludes.push('changes');
                    filterGroups.push(`((changes.is_hidden:equals:0))`);
                    filterGroups.push(
                        `((changes.manufacturer_id:equals:${manufacturerId}))`
                    );
                }

                if (filterGroups.length > 0) {
                    filterGroupsString = `&filter_groups=${filterGroups.join(
                        ' AND '
                    )}`;
                }

                return `product/doors/search?${queryString}&sort_orders=${sort}&related_includes=${relatedIncludes.join(
                    ','
                )}${filterGroupsString}`;
            },
            transformResponse: (response: {
                items: Door[];
                pagination: Pagination;
            }) => ({
                data: response.items
                    .map(transformDoorImage)
                    .map(transformEdgeFinishValues),
                pagination: response.pagination,
            }),
            providesTags: (result, error, {keywords}) => [
                {type: 'Door', keywords},
            ],
        }),
        searchMaterialEdges: builder.query<
            APIResponse<MaterialEdge[]>,
            MaterialSearchRequest
        >({
            query: ({
                keywords,
                doorFilter,
                currentPage = 1,
                pageSize = 20,
                cabinetType,
            }) => {
                let queryString = `keywords=${keywords}&door_filter=${doorFilter}&current_page=${currentPage}&page_size=${pageSize}`;

                if (cabinetType) {
                    queryString += `&cabinet_type=${cabinetType}`;
                }

                return `product/edges/search?${queryString}&sort_orders=name,material_type.name,brand.name,finish,thickness`;
            },
            transformResponse: (
                response: {
                    items: MaterialEdge[];
                    pagination: Pagination;
                },
                meta,
                args
            ) => ({
                data: response.items
                    .map(transformDisplayNameEdge)
                    .map(transformMaterialImage)
                    .map(
                        transformHighlightSearch(
                            args.keywords,
                            'displayName',
                            'highlightedDisplayName'
                        )
                    ),
                pagination: response.pagination,
            }),
            providesTags: (result, error, {keywords}) => [
                {type: 'MaterialEdge', keywords},
            ],
        }),
        searchMaterialEdgesByCritria: builder.query<
            APIResponse<MaterialEdge[]>,
            MaterialEdgeSearchRequest
        >({
            query: searchEdges,
            transformResponse: (response: {
                items: MaterialEdge[];
                pagination: Pagination;
            }) => ({
                data: response.items
                    .map(transformDisplayNameEdge)
                    .map(transformMaterialImage),
                pagination: response.pagination,
            }),
            providesTags: (
                result,
                error,
                {pageSize, currentPage, ...params}
            ) => [{type: 'MaterialEdge', key: JSON.stringify(params)}],
        }),
        listMaterialFinishes: builder.query<
            APIResponse<Finish[]>,
            MaterialSearchRequest
        >({
            query: ({doorFilter, cabinetType, brands, materialType}) => {
                const filterGroup = [`((deleted:equals:0))`];

                if (doorFilter) {
                    filterGroup.push(
                        `((door.filter_name:equals:${doorFilter}))`
                    );
                }

                if (brands) {
                    filterGroup.push(`((brand:either:${brands.join(',')}))`);
                }

                if (cabinetType) {
                    filterGroup.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (materialType) {
                    filterGroup.push(`((type:equals:${materialType}))`);
                }

                return `product/material/finish?filter_groups=${filterGroup.join(
                    ' AND '
                )}&sort_orders=name%20ASC&page_size=9999`;
            },
            transformResponse: (response: {
                items: Finish[];
                pagination: Pagination;
            }) => ({
                data: response.items,
                pagination: response.pagination,
            }),
            providesTags: (
                result,
                error,
                {doorFilter, cabinetType, brands}
            ) => [{type: 'MaterialFinishes', doorFilter, cabinetType, brands}],
        }),
        listEdgeFinishes: builder.query<
            APIResponse<Finish[]>,
            MaterialSearchRequest
        >({
            query: ({doorFilter, cabinetType, brands, materialType}) => {
                const filterGroup = [`((deleted:equals:0))`];

                if (doorFilter) {
                    filterGroup.push(
                        `((door.filter_name:equals:${doorFilter}))`
                    );
                }

                if (brands) {
                    filterGroup.push(`((brand:either:${brands.join(',')}))`);
                }

                if (typeof cabinetType !== 'undefined') {
                    filterGroup.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (materialType) {
                    filterGroup.push(`((type:equals:${materialType}))`);
                }

                return `product/edge/finish?filter_groups=${filterGroup.join(
                    ' AND '
                )}&sort_orders=name%20ASC&page_size=9999`;
            },
            transformResponse: (response: {
                items: Finish[];
                pagination: Pagination;
            }) => ({
                data: response.items,
                pagination: response.pagination,
            }),
            providesTags: (
                result,
                error,
                {doorFilter, cabinetType, brands}
            ) => [{type: 'EdgeFinishes', doorFilter, cabinetType, brands}],
        }),
        listProductMaterialBrands: builder.query<
            APIResponse<Brand[]>,
            MaterialSearchRequest
        >({
            query: ({doorFilter, cabinetType, materialType}) => {
                const filterGroup = [`((deleted:equals:0))`];

                if (doorFilter) {
                    filterGroup.push(
                        `((door.filter_name:equals:${doorFilter}))`
                    );
                }

                if (typeof cabinetType !== 'undefined') {
                    filterGroup.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (materialType) {
                    filterGroup.push(
                        `((material.type:equals:${materialType}))`
                    );
                }

                return `product/material/brands?filter_groups=${filterGroup.join(
                    ' AND '
                )}&sort_orders=name%20ASC&page_size=9999`;
            },
            transformResponse: (response: {
                items: Brand[];
                pagination: Pagination;
            }) => ({
                data: response.items,
                pagination: response.pagination,
            }),
            providesTags: (result, error, {doorFilter, cabinetType}) => [
                {type: 'MaterialBrands', doorFilter, cabinetType},
            ],
        }),
        listEdgeBrands: builder.query<
            APIResponse<Brand[]>,
            MaterialSearchRequest
        >({
            query: ({doorFilter, cabinetType, materialType}) => {
                const filterGroup = [`((deleted:equals:0))`];

                if (doorFilter) {
                    filterGroup.push(
                        `((door.filter_name:equals:${doorFilter}))`
                    );
                }

                if (typeof cabinetType !== 'undefined') {
                    filterGroup.push(`((cabinet.type:equals:${cabinetType}))`);
                }

                if (materialType) {
                    filterGroup.push(`((edge.type:equals:${materialType}))`);
                }

                return `product/edge/brands?filter_groups=${filterGroup.join(
                    ' AND '
                )}&sort_orders=name%20ASC&page_size=9999`;
            },
            transformResponse: (response: {
                items: Brand[];
                pagination: Pagination;
            }) => ({
                data: response.items,
                pagination: response.pagination,
            }),
            providesTags: (result, error, {doorFilter, cabinetType}) => [
                {type: 'MaterialEdgeBrands', doorFilter, cabinetType},
            ],
        }),
        getMatchedEdge: builder.query<
            APIResponse<MaterialEdge[]>,
            MaterialSearchRequest
        >({
            query: ({
                keywords,
                doorFilter,
                cabinetType,
                brand,
                finish,
                materialType,
            }) => {
                let queryString = `colour=${keywords}&brand=${brand}&finish=${finish}`;

                if (typeof cabinetType !== 'undefined') {
                    queryString += `&cabinet_type=${cabinetType}`;
                }

                if (doorFilter) {
                    queryString += `&door_filter=${doorFilter}`;
                }

                if (materialType) {
                    queryString += `&type=${materialType}`;
                }

                return `product/edges/matched-edges?${queryString}`;
            },
            transformResponse: (response: {items: MaterialEdge[]}) => ({
                data: response.items
                    .map(transformDisplayNameEdge)
                    .map(transformMaterialImage),
                pagination: null,
            }),
            providesTags: (result, error, props) => [
                {type: 'MaterialEdgeMatch', ...props},
            ],
        }),
    }),
});

export const {
    useLazySearchMaterialsQuery,
    useLazySearchDoorsQuery,
    useLazySearchMaterialEdgesQuery,
    useLazyGetDoorQuery,
    useLazySearchMaterialEdgesByCritriaQuery,
    useLazyListMaterialsQuery,
    useLazyListDoorsQuery,
    useGetTypesQuery,
    useListDoorsQuery,
    useSearchDoorsQuery,
    useListMaterialFinishesQuery,
    useListProductMaterialBrandsQuery,
    useLazyListMaterialEdgesQuery,
    useListEdgeBrandsQuery,
    useLazyGetMatchedEdgeQuery,
    useListEdgeFinishesQuery,
    useLazyListProductMaterialBrandsQuery,
    useLazyListMaterialFinishesQuery,
    useLazyListEdgeBrandsQuery,
    useLazyListEdgeFinishesQuery,
} = MaterialApi;
