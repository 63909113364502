import depot from 'store/customer/depotSlice';
import auth from 'components/customer/Auth/store/authSlice';
import branding from 'store/customer/brandingSlice';
import materials from 'components/customer/Materials/store/materialSlice';
import qfp from 'components/customer/QFPRedux/store/qfpSlice';
import application from 'store/customer/appSlice';
import edgeFinish from 'components/customer/EdgeFinishes/store/edgeFinishSlice';
import btm from 'components/customer/BTM/store/btmSlice';
import roomLoader from 'components/customer/Room/store/roomLoaderSlice';
import roomError from 'components/customer/Room/store/roomErrorSlice';
import advancedMaterials from 'components/customer/AdvancedMaterials/store/materialSlice';
import UI from 'store/customer/UISlice';
import room from 'components/customer/Room/store/roomSlice';
import settings from 'components/customer/Settings/store/settingsSlice';
import viewerSlice from 'components/customer/Preview3DCommon/store/viewerSlice';
import drawerRunner from 'components/customer/Product/store/drawerRunnerSlice';
import roomPlannerSlice from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import roomProducts from 'components/customer/Product/store/roomProductsSlice';

import {combineReducers} from '@reduxjs/toolkit';

import {apiReducerPath, apiReducer} from 'store/customer/api';

export const createReducer = () => {
    const reducers = {
        depot,
        auth,
        materials,
        qfp,
        theme: branding,
        application,
        edgeFinish,
        btm,
        room,
        roomLoader,
        roomError,
        advancedMaterials,
        settings,
        preview3D: viewerSlice,
        roomPlanner: roomPlannerSlice,
        [apiReducerPath]: apiReducer,
        UI,
        drawerRunner,
        roomProducts,
    };

    // NOTE: This should not affect how ui functions. Once all
    // ui tests are moved to cypress we can remove line 35 - 39
    // as it is somehow failing jest tests.
    Object.keys(reducers).forEach((key) => {
        if (typeof reducers[key] === 'undefined') {
            delete reducers[key];
        }
    });

    return combineReducers(reducers);
};
