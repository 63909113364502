import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {
    RoomType,
    RectangularDimension,
    LShapedDimension,
    RoomPlannerProduct,
    ActiveGroupPosition,
} from 'components/customer/RoomPlanner/types';
import {PreviewPosition, PreviewFormValues} from 'Preview3D/types';
import * as THREE from 'three';

interface RoomPlanner {
    isRoomPlannerEditing: boolean;
    isDragging: boolean;
    activeGroup: THREE.Group | null;
    activeGroupPosition: ActiveGroupPosition | null;
    scale: number;
    roomLayoutModalOpen: boolean;
    roomType: RoomType | null;
    rectangularDimension: RectangularDimension;
    lShapedDimension: LShapedDimension;
    products: RoomPlannerProduct[];
    previewPosition: PreviewPosition | null;
    zoom: number;
    showArrows: boolean;
    showCabinets: boolean;
    selectedProductValue: PreviewFormValues | null;
    productValuesForUpdate: PreviewFormValues | null;
    isAutoSaving: boolean;
    hiddenWall: string | null;
    isTopView: boolean;
    rectangularElementDimension: RectangularDimension | null;
    lShapedElementDimension: LShapedDimension | null;
    topViewZoom: number;
    isEditHeightView: boolean;
    isWireframeMode: boolean;
}

interface UpdateDimensionPayload {
    field: string;
    value: number;
    type: RoomType;
}

const roomPlannerSlice = createSlice({
    name: 'room_planner',
    initialState: {
        isRoomPlannerEditing: false,
        isDragging: false,
        activeGroup: null,
        scale: 0.2,
        roomLayoutModalOpen: true,
        roomType: null,
        rectangularDimension: {
            length: 8000,
            width: 8000,
            height: 2800,
        },
        lShapedDimension: {
            leftWidth: 8000,
            rightWidth: 8000,
            leftDepth: 4200,
            rightDepth: 4200,
            height: 2800,
        },
        products: [],
        previewPosition: 'TOP',
        zoom: 1,
        showArrows: false,
        showCabinets: true,
        selectedProductValue: null,
        productValuesForUpdate: null,
        isAutoSaving: false,
        hiddenWall: null,
        isTopView: true,
        rectangularElementDimension: null,
        lShapedElementDimension: null,
        topViewZoom: 1,
        isEditHeightView: false,
        isWireframeMode: false,
    } as RoomPlanner,
    reducers: {
        setIsRoomPlannerEditing: (state, action: PayloadAction<boolean>) => {
            state.isRoomPlannerEditing = action.payload;
        },
        setIsDragging: (state, action: PayloadAction<boolean>) => {
            state.isDragging = action.payload;
        },
        setActiveGroup: (state, action: PayloadAction<THREE.Group | null>) => {
            state.activeGroup = action.payload;
        },
        setActiveGroupPosition: (
            state,
            action: PayloadAction<ActiveGroupPosition | null>
        ) => {
            state.activeGroupPosition = action.payload;
        },
        setRoomType: (state, action: PayloadAction<RoomType | null>) => {
            state.roomType = action.payload;
        },
        setRoomLayoutModalOpen: (state, action: PayloadAction<boolean>) => {
            state.roomLayoutModalOpen = action.payload;
        },
        updateDimension: (
            state,
            action: PayloadAction<UpdateDimensionPayload>
        ) => {
            if (action.payload.type === 'RECTANGULAR') {
                state.rectangularDimension[
                    action.payload
                        .field as keyof typeof state.rectangularDimension
                ] = action.payload.value;
            }

            if (action.payload.type === 'LSHAPED') {
                state.lShapedDimension[
                    action.payload.field as keyof typeof state.lShapedDimension
                ] = action.payload.value;
            }
        },
        setProducts: (state, action: PayloadAction<RoomPlannerProduct[]>) => {
            state.products = action.payload;
        },
        setPreviewPosition: (state, action: PayloadAction<PreviewPosition>) => {
            if (action.payload) state.isTopView = action.payload === 'TOP';
            if (action.payload)
                state.isEditHeightView =
                    action.payload === 'LEFT_LEVELED_STATIC';
            state.previewPosition = action.payload;
        },
        setZoom: (state, action: PayloadAction<number>) => {
            state.zoom = action.payload;
        },
        setShowArrows: (state, action: PayloadAction<boolean>) => {
            state.showArrows = action.payload;
        },
        setShowCabinets: (state, action: PayloadAction<boolean>) => {
            state.showCabinets = action.payload;
        },
        setSelectedProductValue: (
            state,
            action: PayloadAction<PreviewFormValues | null>
        ) => {
            state.selectedProductValue = action.payload;
        },
        setProductValuesForUpdate: (
            state,
            action: PayloadAction<PreviewFormValues | null>
        ) => {
            state.productValuesForUpdate = action.payload;
        },
        setIsAutoSaving: (state, action: PayloadAction<boolean>) => {
            state.isAutoSaving = action.payload;
        },
        setHiddenWall: (state, action: PayloadAction<string | null>) => {
            state.hiddenWall = action.payload;
        },
        setRectangularElementDimension: (
            state,
            action: PayloadAction<RectangularDimension | null>
        ) => {
            state.rectangularElementDimension = action.payload;
        },
        setLShapedElementDimension: (
            state,
            action: PayloadAction<LShapedDimension | null>
        ) => {
            state.lShapedElementDimension = action.payload;
        },
        setTopViewZoom: (state, action: PayloadAction<number>) => {
            state.topViewZoom = action.payload;
        },
        setIsWireframeMode: (state, action: PayloadAction<boolean>) => {
            state.isWireframeMode = action.payload;
        },
    },
});

export const getScale = (state: AppState) => state.roomPlanner.scale;
export const getRoomType = (state: AppState) => state.roomPlanner.roomType;
export const getRoomLayoutModalOpen = (state: AppState) =>
    state.roomPlanner.roomLayoutModalOpen;

export const getActiveGroup = (state: AppState) =>
    state.roomPlanner.activeGroup;

export const getActiveGroupPosition = (state: AppState) =>
    state.roomPlanner.activeGroupPosition;

export const getIsDragging = (state: AppState) => state.roomPlanner.isDragging;
export const getisRoomPlannerEditing = (state: AppState) =>
    state.roomPlanner.isRoomPlannerEditing;

export const getPreviewPosition = (state: AppState) =>
    state.roomPlanner.previewPosition;

export const getRoomDimensions = (state: AppState) => ({
    rectangularDimension: state.roomPlanner.rectangularDimension,
    lShapedDimension: state.roomPlanner.lShapedDimension,
});
export const getProducts = (state: AppState) => state.roomPlanner.products;
export const getZoom = (state: AppState) => state.roomPlanner.zoom;
export const getShowArrows = (state: AppState) => state.roomPlanner.showArrows;
export const getShowCabinets = (state: AppState) =>
    state.roomPlanner.showCabinets;
export const getSelectedProductValue = (state: AppState) =>
    state.roomPlanner.selectedProductValue;
export const getProductValuesForUpdate = (state: AppState) =>
    state.roomPlanner.productValuesForUpdate;
export const getIsAutoSaving = (state: AppState) =>
    state.roomPlanner.isAutoSaving;
export const getHiddenWall = (state: AppState) => state.roomPlanner.hiddenWall;
export const getIsTopView = (state: AppState) => state.roomPlanner.isTopView;
export const getRectangularElementDimension = (state: AppState) =>
    state.roomPlanner.rectangularElementDimension;
export const getTopViewZoom = (state: AppState) =>
    state.roomPlanner.topViewZoom;
export const getIsEditHeightView = (state: AppState) =>
    state.roomPlanner.isEditHeightView;
export const getLShapedElementDimension = (state: AppState) =>
    state.roomPlanner.lShapedElementDimension;
export const getIsWireframeMode = (state: AppState) =>
    state.roomPlanner.isWireframeMode;

export const {
    setActiveGroupPosition,
    setIsDragging,
    setIsRoomPlannerEditing,
    setActiveGroup,
    setRoomType,
    setRoomLayoutModalOpen,
    updateDimension,
    setProducts,
    setPreviewPosition,
    setZoom,
    setShowArrows,
    setShowCabinets,
    setSelectedProductValue,
    setProductValuesForUpdate,
    setIsAutoSaving,
    setHiddenWall,
    setRectangularElementDimension,
    setLShapedElementDimension,
    setTopViewZoom,
    setIsWireframeMode,
} = roomPlannerSlice.actions;

export default roomPlannerSlice.reducer;
