import {ReactElement} from 'react';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';

export enum SidebarStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export enum SidebarBehaviour {
    INVISIBLE = 'INVISIBLE',
    AUTOHIDE = 'AUTOHIDE',
    TOGGLE = 'TOGGLE',
    SOFT_OPEN = 'SOFT_OPEN',
}

export enum SidebarName {
    LEFT = 'LeftSidebarV2',
    RIGHT = 'RightSidebarV2',
}

export enum SidebarBehaviourName {
    LEFT = 'LeftSidebarBehaviourV2',
    RIGHT = 'RightSidebarBehaviourV2',
}

export interface Sidebar {
    status: SidebarStatus;
    behaviour: SidebarBehaviour;
}

export interface UISliceInterface {
    rightSidebar: Sidebar;
    leftSidebar: Sidebar;
    alternateCartContent: ReactElement;
    isSmallScreenLayout: boolean;
}

export const storageValue = (name: string, defaultValue: string): string => {
    const sidebarValue = localStorage.getItem(name);

    if (sidebarValue == null || sidebarValue?.toLowerCase() == 'undefined') {
        return defaultValue?.toUpperCase();
    }

    return sidebarValue?.toUpperCase();
};

const leftSidebarStorage = storageValue(SidebarName.LEFT, SidebarStatus.OPEN);
const leftSidebarBehaviorStorage = storageValue(
    SidebarBehaviourName.LEFT,
    SidebarBehaviour.TOGGLE
);

const rightSidebarStorage = storageValue(SidebarName.RIGHT, SidebarStatus.OPEN);
const rightSidebarBehaviorStorage = storageValue(
    SidebarBehaviourName.RIGHT,
    SidebarBehaviour.TOGGLE
);

export const defaultRightSidebarBehavior =
    SidebarBehaviour[
        rightSidebarBehaviorStorage as keyof typeof SidebarBehaviour
    ];

export const defaultLeftSidebarBehavior =
    SidebarBehaviour[
        leftSidebarBehaviorStorage as keyof typeof SidebarBehaviour
    ];

export const defaultRightSidebar =
    SidebarStatus[rightSidebarStorage as keyof typeof SidebarStatus];

export const defaultLeftSidebar =
    SidebarStatus[leftSidebarStorage as keyof typeof SidebarStatus];

const initialState: UISliceInterface = {
    rightSidebar: {
        status: defaultRightSidebar,
        behaviour: defaultRightSidebarBehavior,
    },
    leftSidebar: {
        status: defaultLeftSidebar,
        behaviour: defaultLeftSidebarBehavior,
    },
    alternateCartContent: null,
    isSmallScreenLayout: false,
};

export const UISlice = createSlice({
    name: 'userInterface',
    initialState,
    reducers: {
        setRightSidebarStatus: (
            state,
            {payload}: PayloadAction<SidebarStatus>
        ) => {
            state.rightSidebar.status = payload;
        },
        setRightSidebarBehaviour: (
            state,
            {payload}: PayloadAction<SidebarBehaviour>
        ) => {
            state.rightSidebar.behaviour = payload;
        },
        setLeftSidebarStatus: (
            state,
            {payload}: PayloadAction<SidebarStatus>
        ) => {
            state.leftSidebar.status = payload;
        },
        setLeftSidebarBehaviour: (
            state,
            {payload}: PayloadAction<SidebarBehaviour>
        ) => {
            state.leftSidebar.behaviour = payload;
        },
        setAlternateCartContent: (
            state,
            {payload}: PayloadAction<ReactElement>
        ) => {
            state.alternateCartContent = payload;
        },
        setIsSmallScreenLayout: (state, {payload}: PayloadAction<boolean>) => {
            state.isSmallScreenLayout = payload;
        },
    },
});

export const selectLeftSidebar = (state: AppState) =>
    state.UI.leftSidebar.status;
export const selectLeftSidebarBehaviour = (state: AppState) =>
    state.UI.leftSidebar.behaviour;

export const selectRightSidebar = (state: AppState) =>
    state.UI.rightSidebar.status;
export const selectRightSidebarBehaviour = (state: AppState) =>
    state.UI.rightSidebar.behaviour;

export const selectAlternateCartContent = (state: AppState) =>
    state.UI.alternateCartContent;
export const selectIsSmallScreenLayout = (state: AppState) =>
    state.UI.isSmallScreenLayout;

export const {
    setRightSidebarStatus,
    setRightSidebarBehaviour,
    setLeftSidebarStatus,
    setLeftSidebarBehaviour,
    setAlternateCartContent,
    setIsSmallScreenLayout,
} = UISlice.actions;

export default UISlice.reducer;
