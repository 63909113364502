import {AppApi} from 'store/customer/api';
import {mapProductForRoomPlanner} from 'components/customer/RoomPlanner/helpers/objectMapper';
import {mapMaterial} from 'components/customer/Materials/helper/materialHelper';
import {
    RoomPlannerProductApi,
    RoomPlannerProduct,
} from 'components/customer/RoomPlanner/types';

export const roomPlannerApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoomPlannerProducts: builder.query<
            RoomPlannerProduct[],
            {roomId: number}
        >({
            query: ({roomId}) => `room-planner/${roomId}`,
            transformResponse: (response: {
                room_planner_job_cabinets: RoomPlannerProductApi[];
            }) => {
                if (response && response.room_planner_job_cabinets) {
                    return response.room_planner_job_cabinets
                        .map(mapProductForRoomPlanner)
                        .map((product) => ({
                            ...product,
                            extMaterial: mapMaterial(product.ext_material),
                            carcMaterial: mapMaterial(product.carc_material),
                        }))
                        .sort((a, b) => a.room_cab_number - b.room_cab_number);
                }

                return [];
            },
            providesTags: (response, error, {roomId}) => [
                {type: 'ProductRoomPlanner', roomId},
            ],
        }),
    }),
});

export const {useGetRoomPlannerProductsQuery} = roomPlannerApi;
